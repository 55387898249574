<template>
  <div class="content" >
      <div class="jurisdiction-left">
          <el-card class="jurisdiction-left-card">
              <div class="jurisdiction-left-title">
                  <span class="text_left">用户组</span>
              </div>
              <div v-for="item in user_group_list" >
                <div :class="item.selected?'user_group_select':'user_group'"  @click="user_group_selected(item)" v-if="item.status==1">
                    <div class="user_group_content text_left">
                        <div class="user_group_left">
                            <div >
                                <div class="max_title">{{item.title}}</div>
                                <div class="min_title">{{item.min_title}}</div>
                            </div>
                        </div>
                        <div class="user_group_right" @click="user_group_right('group',item)">
                            <div :class="item.selected?'user_group_right_div_select':'user_group_right_div'"></div>
                            <div :class="item.selected?'user_group_right_div_select':'user_group_right_div'"></div>
                            <div :class="item.selected?'user_group_right_div_select':'user_group_right_div'"></div>
                        </div>
                    </div>
                </div>
                <div class="group_Disable" v-else="">
                    <div class="user_group_content text_left">
                        <div class="fist_disable">
                           <div class="Disable_div"></div>
                        </div>
                        <div class="user_group_left">
                             
                            <div style="    height: 60%;">
                                <div class="max_title">{{item.title}}</div>
                                <div class="min_title">该组已被禁用</div>
                            </div>
                        </div>
                        <div class="user_group_right" @click="user_group_right('group',item)">
                            <div class="user_group_right_div"></div>
                            <div class="user_group_right_div"></div>
                            <div class="user_group_right_div"></div>
                        </div>
                    </div>
                </div>

              </div>
              



              <div class="jurisdiction-left-title" @click="dialogVisible = true">
                   <div style="color:#38A28A;font-size:14px;cursor:default" >
                        <div class="text_left" >
                            <i class="el-icon-plus"></i>
                            <span style="margin-left:3px">添加用户分组</span>
                        </div>
                    </div>
              </div>

          </el-card>
          <div class="user_group_menu" :style="'top:'+menu_top+';display:'+menu_display+';left:'+menu_left">
              <div>
                <div @click="selected_menu(item)" :class="item.selected?'user_group_menu_selected':'user_group_menu_detail'" v-for="item in menu_list">
                    <span>{{item.name}}</span>
                </div>
                
              </div>
          </div>

        <el-dialog
            title="添加用户分组"
            :visible.sync="dialogVisible"
            width="360px">
            <div>
                <div class="dialog_div">
                    <div><span>分组名称</span></div>
                    <div><el-input placeholder="分组名称" v-model="group.title"></el-input></div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer dialog_div" >
                <el-button class="primary_btn" type="primary" @click="Submit_adduser_group()">确 定</el-button>
            </div>
        </el-dialog>


      </div>
      <div class="jurisdiction-right" @click="other_click">
          <el-card>
              <div style="margin-left:10px" class="header_manager">
                  <div>公司管理员</div>
                  <div @click="update_route()">刷新权限列表</div>
              </div>
              <div>
                  <el-table v-loading="loding" :data="user_list" :header-cell-style="table_cell_style" border>
                        <el-table-column label="权限类型" width="180">
                            <template slot-scope="scope" >
                                 <el-checkbox :disabled="!btn_isshow" :checked="scope.row.checked" @change="allchangeChecks(scope.row)">{{scope.row.jurisdiction_type}}</el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column label="权限" >
                            <template slot-scope="scope">
                                <el-checkbox :disabled="!btn_isshow" class="checkbox" v-for="item in scope.row.Rulelist" :checked="item.checked" @change="changeChecks(item)">{{item.title}}</el-checkbox>
                            </template>
                        </el-table-column>
                  </el-table>
              </div>
              <div class="jurisdiction-right_center">
                  <div v-show="btn_isshow" @click="SavaAuthGroupRule"><span>确定</span></div>
                  <div v-show="btn_isshow" @click="reset_rules"><span>重置</span></div>
              </div>

              <div class="jurisdiction-right_center_bottom">
                  <div><span>成员管理（{{user_manage_list.length}}）</span></div>
                  <div v-show="btn_isshow" @click="getauthGroupUserList()"><span>+添加成员</span></div>
              </div>

              <div class="jurisdiction-right_bottom">
                  <div v-for="item in user_manage_list" @click="user_group_right('user_manage',item)">
                      <div class="head_img" :style="'background-image:url('+item.avatar+')'"></div>
                      <div><span>{{item.username}}</span></div>
                  </div>
                  
              </div>
          </el-card>
          <div class="user_group_menu" :style="'top:'+menu_top+';display:'+user_manage_dialog_show+';left:'+menu_left">
             <div>
                  <div @click="selected_menu(item)" :class="item.selected?'user_group_menu_selected':'user_group_menu_detail'" v-for="item in user_dialog_menu_list" ><span>{{item.name}}</span></div>
             </div>
          </div>
          
          <el-dialog
            title="添加成员"
            :visible.sync="dialogAdduser"
           >
                <div class="dialog_div_adduser">
                    <div >
                        <div @click="selected_roles(item)" v-for="item in default_roles" :class="item.selected?'dialog_div_adduser_selected':''"><span>{{item.name}}</span></div>
                    </div>
                     <div >
                        <div><span>搜索：</span></div>
                        <div><el-input placeholder="请输入用户名" v-model="post_data.username"></el-input></div>
                        <div><el-button class="primary_btn" type="primary" @click="getauthGroupUserList()">查询</el-button></div>
                        <div><el-button class="primary_btn" type="default"  @click="adduser_reset()">重置</el-button></div>
                    </div>
                </div>
                <div>
                    <el-table  tooltip-effect="dark" :data="Unallocated_users" @selection-change="table_all_check">
                        <el-table-column type="selection" prop="id" >
                        </el-table-column>
                        <el-table-column label="用户名" prop="username">
                        </el-table-column>
                        <el-table-column label="手机" prop="phone">
                        </el-table-column>
                        <el-table-column label="头像">
                            <template slot-scope="scope">
                                <div> 
                                    <el-image
                                        style="width: 40px; height: 40px"
                                        :src="scope.row.avatar"
                                        fit="contain"></el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="身份">
                            <template slot-scope="scope">
                                <div><span>{{scope.row.role==1?'管理员':scope.row.role==2?'老师':scope.row.role==3?'销售':'...'}}</span></div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div slot="footer" class="dialog-footer dialog_div" >
                    <el-button class="primary_btn" type="primary" @click="addmanageuser()">确 定</el-button>
                </div>
          </el-dialog>
        
      </div>
  </div>
</template>

<script>
import {
    authGroupList,
    authRuleList,
    authGroupEnable,
    authGroupDel,
    authGroupAccess,
    authGroupRule,
    authGroupUserDel,
    authGroupAdd,
    authGroupUserList,
    authGroupUserSave,
    authUpdateRoute,
    } from "@/api/Jurisdiction"

export default { 
    data(){
        return {
            user_group_list:[],
            menu_top:"170px", //菜单位置
            menu_display:'none', //是否隐藏菜单
            menu_left:"0px",
            dialogVisible:false,
            dialogAdduser:false,
            group:{title:''}, //添加用户组参数
            menu_list:[
                 {
                        name:'禁用',
                        selected:false,
                        rek:"Disable"
                    },{
                        name:'删除',
                        selected:false,
                        rek:"Del"
                    },{
                        name:'取消',
                        selected:false,
                        rek:"Cancel"
                    }
            ],
            user_list:[],
            loding:true,
            post_data:{},//put,post,delete参数
            user_manage_list:[],
            btn_isshow:false,//确认与重置按钮的显示开关
            user_manage_dialog_show:'none', //用户头像点击后菜单控制
            user_dialog_menu_list:[
                {
                    name:'删除',
                    selected:false,
                    rek:"user_Delete"
                },
                {
                     name:'取消',
                    selected:false,
                    rek:"user_Cancel"
                }
            ],//单击用户组成员头像
            default_roles:[
                {
                    name:'全部',
                    selected:true,
                    value:0

                },
                 {
                    name:'管理员',
                    selected:false,
                    value:1

                },
                 {
                    name:'老师',
                    selected:false,
                    value:2

                },
                 {
                    name:'销售',
                    selected:false,
                    value:3

                },
            ],
            Unallocated_users:[],
      }
    },
    mounted:function(){
        this.getusergroup()
        this.getuserJurisdiction()
      
    },
    methods:{
        //获取用户组列表
        getusergroup(){
            let that=this
            authGroupList().then(res=>{
                if(res.code===1){
                    that.user_group_list=res.data
                   let lenght=Object.keys(that.user_group_list).length
                   
                    for(var i=0;i<lenght;i++){
                     
                            that.user_group_list[i].selected=false
                    }

                }
            })
        },
        //保存用户组状态
        saveauthGroupEnable(){
            authGroupEnable(this.post_data).then(res=>{
                        if(res.code===1){
                            that.$message({
                                showClose: true,
                                message: res.msg,
                                type: 'success'
                            })

                        }
                    })
                    
        },
        //获取用户组权限
        getuserJurisdiction(){
           let that=this
           that.user_list=[]
           that.loding=true
           authRuleList().then(res=>{
               that.user_list.push(res.data)
                let arry=new Array();
                for(var i=0;i<that.user_list.length;i++){
                    for(var key in that.user_list[i]){
                        var obj={
                                jurisdiction_type:key,
                                checked:false,
                                Rulelist:[]
                            }

                        for(var j=0,n=that.user_list[i][key].length;j<n;j++){
                            var rule=that.user_list[i][key][j]
                            rule.checked=false
                            obj.Rulelist.push(rule)
                        }
                        arry.push(obj)
                    }
                }
                that.user_list=arry
               that.loding=false
           })
       },
        //获取用户组成员
        getauthGroupAccess(id){
           let that=this
            that.user_manage_list=[]
           authGroupAccess({id:id}).then(res=>{
               that.user_manage_list=res.data
           })
       },
        //鼠标点击选中
        user_group_selected(item){
            let that=this
            let lenght=Object.keys(that.user_group_list).length
            for(var i=0;i<lenght;i++){
                that.user_group_list[i].selected=false
            }
            item.selected=true
            let user_group_list= that.user_group_list
            that.user_group_list=[]
            that.user_group_list=user_group_list
            that.post_data.id=item.id
            that.Comparative_data(item)
           
            that.btn_isshow=true
            this.getauthGroupAccess(item.id)
        },
        //鼠标点击小圆点与用户头像
        user_group_right(value,item){
            this.menu_top=event.clientY+"px"
            this.menu_display=''
            this.menu_left=event.clientX+"px"
            if(value=='user_manage'){
                this.user_manage_dialog_show=''

                this.post_data.rbac_id=item.id
                console.log(item)
            }
            else{
                this.post_data.id=item.id
                this.menu_display=''
                this.menu_list.shift()
                if(item.status==1){
                    this.menu_list.unshift(
                         {
                            name:'禁用',
                            selected:false,
                            rek:"Disable"
                        }
                    )
                }else{
                    this.menu_list.unshift(
                    {
                        name:'启用',
                        selected:false,
                        rek:"Enable"
                    })
                }
            }
        },
        //菜单选项
        selected_menu(item){
            let that=this
            for(var i=0;i<this.menu_list.length;i++){
                this.menu_list[i].selected=false
            }
            for(var i=0;i<this.user_dialog_menu_list.length;i++){
                this.user_dialog_menu_list[i].selected=false
            }
            item.selected=true;
            switch(item.rek){
                case 'Cancel':
                     this.menu_display='none'
                     item.selected=false
                     break
                case 'Del':
                    this.$confirm('此操作将永久删除该用户组, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(function(){
                        authGroupDel(that.post_data).then(res=>{
                            if(res.code===1){
                                that.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                })
                            }
                            that.getusergroup()
                        })  
                    }).catch(res=>{

                    })
                    that.$nextTick(res=>{
                        this.menu_display='none'
                        item.selected=false
                    })
                    
                    break
                case 'Disable':
                    this.post_data.enable=0
                  this.saveauthGroupEnable()
                  this.$nextTick(res=>{
                         var length=Object.keys(this.user_group_list).length
                         for(var i=0;i<length;i++){
                            if(this.user_group_list[i].id==this.post_data.id){
                                this.user_group_list[i].status=this.post_data.enable
                            }
                         }
                         this.user_list=[]
                         this.btn_isshow=false
                         this.getuserJurisdiction()
                         let arr=[]
                        
                     })
                     this.menu_display='none'
                     item.selected=false
                    break
                case 'Enable':
                    this.post_data.enable=1
                    this.saveauthGroupEnable()
                    
                    this.$nextTick(res=>{
                         var length=Object.keys(this.user_group_list).length
                         for(var i=0;i<length;i++){
                            if(this.user_group_list[i].id==this.post_data.id){
                                this.user_group_list[i].status=this.post_data.enable
                                this.btn_isshow=true
                                this.Comparative_data(this.user_group_list[i])
                            }
                         }
                     })
                     this.menu_display='none'
                     item.selected=false
                    
                    break
                case 'user_Delete':
                    this.$confirm('此操作将永久删除该用户组成员, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(function(){
                        authGroupUserDel(that.post_data).then(res=>{
                            if(res.code===1){
                                that.$message({
                                        showClose: true,
                                        message: res.msg,
                                        type: 'success'
                                    })
                                that.$nextTick(res=>{
                                    that.getauthGroupAccess(that.post_data.id)
                                    that.user_manage_dialog_show='none'
                                    item.selected=false
                                })
                            }
                        })
                       
                    }).catch(res=>{
                            that.user_manage_dialog_show='none'
                            item.selected=false
                        })
                    break
                case 'user_Cancel':
                     this.user_manage_dialog_show='none'
                     item.selected=false
                    break
           }
        },
        //点击其他地方关闭
        other_click(){
            this.menu_display='none'
        },
        //表格头部样式
        table_cell_style(){
            return {background:'#ffffff !important','font-size':'14px',color:'#969696'}
        },
        //对比数据
        Comparative_data(item){
           this.loding=true
           let that=this
          
          var arry=[],changelist=[]
          //字符串数组转数字
            for(var i=0;i<item.rules.length;i++){
                var ruleid=parseInt(item.rules[i])
                changelist.push(ruleid)
            }
            //重新循环获取值进行绑定更新数据
            let user_list_length=Object.keys(this.user_list).length
            for(var j=0;j<user_list_length;j++){
                  var obj={
                            jurisdiction_type:this.user_list[j].jurisdiction_type,
                            checked:this.user_list[j].checked,
                            Rulelist:[]
                        }
                    let checked_count=0
                  //循环权限接口改动
                  for(var k=0;k<this.user_list[j].Rulelist.length;k++){
                        if(changelist.includes(this.user_list[j].Rulelist[k].id)==true){
                            this.user_list[j].Rulelist[k].checked=true
                            checked_count++
                        }else{
                            this.user_list[j].Rulelist[k].checked=false
                        }
                        obj.Rulelist.push(this.user_list[j].Rulelist[k])
                  }
                  if(checked_count>=obj.Rulelist.length){
                      obj.checked=true
                  }else{
                      obj.checked=false
                  }
                  arry.push(obj)
              }

          this.loding=false
          //重新赋值刷新绑定
          this.user_list=[]
          this.$nextTick(res=>{
              that.user_list=arry
          })

        },
        //保存用户组规则
        SavaAuthGroupRule(){
            let that=this
            let ruleids=[]
            for(var key in this.post_data){
                if(key==='id'||key==='rules'){
                    continue
                }
                else{
                     delete this.post_data[key];
                }
            }
            for(var j=0;j<this.user_list.length;j++){
                  //循环权限接口改动
                  for(var k=0;k<this.user_list[j].Rulelist.length;k++){
                    if(this.user_list[j].Rulelist[k].checked==true){
                        ruleids.push(this.user_list[j].Rulelist[k].id)
                    }
                  }
              }
              this.post_data.rules=ruleids
              
               authGroupRule(this.post_data).then(res=>{
                 if(res.code===1){
                      this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        })
                 }else{
                      this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error'
                        })
                 }
             })

             that.$nextTick(res=>{
                 var length=Object.keys(that.user_group_list).length
                 for(var i=0;i<length;i++){
                        if(that.user_group_list[i].selected==true){
                            that.user_group_list[i].rules=that.post_data.rules
                        }
                    }
             })
        },
        //复选框
        changeChecks(item){
            item.checked=!item.checked
        },
        //权限类型复选框
        allchangeChecks(item){
            let arry=[]
            item.checked=!item.checked
            for(var i=0;i<item.Rulelist.length;i++){
                this.$set(item.Rulelist[i], 'checked', item.checked )
                arry.push(item.Rulelist[i])
            }
            
            item.Rulelist=[]
            this.$nextTick(res=>{
                item.Rulelist=arry
            })
        },
        //确认添加用户组
        Submit_adduser_group(){
           authGroupAdd(this.group).then(res=>{
               if(res.code===1){
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'success'
                    })
                    this.getusergroup()
                 }
           })
           this.$nextTick(res=>{
               this.dialogVisible=false
           })
       },
        //添加成员(获取未分配的成员列表)
        getauthGroupUserList(){
            for(var i=0;i< this.default_roles.length;i++){
                if(this.default_roles[i].selected==true){
                    this.post_data.role=this.default_roles[i].value
                }
            }
            this.dialogAdduser=true
            authGroupUserList(this.post_data).then(res=>{
                console.log(res)
                if(res.code!=1){
                    this.Unallocated_users=[]
                }
                else{
                    this.Unallocated_users=res.data
                }
            })
        },
        //选中弹窗中的角色
        selected_roles(item){
             for(var i=0;i<this.default_roles.length;i++){
                this.default_roles[i].selected=false
            }
            item.selected=true
            this.$nextTick(res=>{
                this.getauthGroupUserList()
            })
        },
        //表格中的复选框
        table_all_check(val){
            this.post_data.rbac_ids=[]
            for(var i=0;i<val.length;i++){
                this.post_data.rbac_ids.push(val[i].id)
            }
        },
        //确认添加用户组成员
        addmanageuser(){
            for(var key in this.post_data){
                if(key==='id'||key==='rbac_ids'){
                    continue
                }
                else{
                     delete this.post_data[key];
                }
            }
            console.log(this.post_data)
            authGroupUserSave(this.post_data).then(res=>{
                if(res.code===1){
                    this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        })
                    this.dialogAdduser=false
                    this.getauthGroupAccess(this.post_data.id)
                }
            })
        },
        //重置添加成员条件
        adduser_reset(){
            this.post_data.username=''
             for(var i=0;i<this.default_roles.length;i++){
                this.default_roles[i].selected=false
            }
            this.default_roles[0].selected=true
            this.getauthGroupUserList()
        },
        //重置权限规则
        reset_rules(){
            console.log(this.user_group_list)
            let that=this
            var length=Object.keys(that.user_group_list).length
            for(var i=0;i<length;i++){
                if(that.user_group_list[i].selected==true){
                    that.Comparative_data(that.user_group_list[i])
                }
            }
        },
        //更新路由
        update_route(){
            this.loding=true
            authUpdateRoute().then(res=>{
                if(res.code===1){
                     this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        })
                        this.getuserJurisdiction()
                        this.loding=false
                }
            })
        }
    },

}

</script>

<style scoped>

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .header_manager{
        display: flex;
        justify-content: space-between;
    }
    .header_manager div:last-child{
        display: flex;
        justify-content: center;
        width: 120px;
        height: 40px;
        background: #38A28A;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 14px;
        align-items: center;
        cursor: pointer;
    }
    .content{
        display: flex;
    }
    .content .jurisdiction-left{
        margin-right: 20px;
        width: 250px;
        padding: 0 20px;
    }
    .content .jurisdiction-right{
        width: 1340px;
    }
    .content .jurisdiction-left ::v-deep .el-card__body{
        padding: 0;
        margin: 0;
    }
    .content .jurisdiction-left .jurisdiction-left-title{
        height: 50px;
        width: 250px;
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
    }
    .text_left{
        margin-left: 20px;
        margin-right: 20px;
    }
    .user_group_content{
        display: flex;
        justify-content: space-between;
        height: 72px;
    }
    
    .max_title{
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        
        line-height: 30px;
    }
    .min_title{
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      
        line-height: 0px;
    }
    .user_group_left{
        display: flex;
        align-items: center;
    }
    .user_group_right{
        display: flex;
        align-items: center;
    }
    /* 用户组右边小圆点 */
    .user_group_right_div{
        width: 8px;
        height: 8px;
        background: #CCCCCC;
        border-radius: 50%;
        margin-left: 1px;
    }
    .user_group_right_div_select{
        width: 8px;
        height: 8px;
        background: #38A28A;
        border-radius: 50%;
        margin-left: 1px;
    }
    /* 选中后的文字颜色与边框颜色 */
    .user_group_select{
        border-left: #38A28A solid 4px;
        color: #80BDAE;
        cursor: pointer;
    }
    .user_group{
        border-bottom: #EEEEEE 1px solid;
        cursor: pointer;
    }
    .user_group_menu{
        background-color: #ffffff;
        border: #CCCCCC 1px solid;
        box-shadow:#CCCCCC 3px 3px 3px 3px; 
        width: 80px;
        position: fixed;
        left: 290px;
        display: flex;
        justify-content: center;
        cursor:pointer;
    }
    .user_group_menu_detail{
        height: 30px;
        color: #969696;
        width: 80px;
    text-align: center;
    }
    .user_group_menu_selected{
        height: 30px;
        color: #38A28A;
        width: 80px;
    text-align: center;
    }
    .checkbox{
        margin-left: 20px;
    }
    .jurisdiction-right_center{
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    .jurisdiction-right_center div{
        width: 120px;
        height: 40px;
        background: #38A28A;
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: default;
    }
    .jurisdiction-right_center_bottom{
        margin-top: 20px;
        margin-left: 10px;
        font-size: 14px;
        display: flex;
    }
    .jurisdiction-right_center_bottom div:last-child{
        margin-left: 10px;
        color: #38A28A;
        cursor: pointer;
    }

    .jurisdiction-right_bottom{
        min-height: 138px;
        background: #F0F0F0;
        margin-left: 10px;
        margin-top: 20px;
      padding-top: 30px;
      padding-left: 10px;
      display: flex;
    }
    .jurisdiction-right_bottom div{ 
        display: flex;
         padding-left: 10px;
         font-size: 14px;
         align-items: center;
         height: 30px;
         cursor: default;
    }
    .jurisdiction-right_bottom .head_img{
        width: 24px;
        height: 24px;
        background: #CCCCCC;
        border-radius: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .dialog_div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dialog_div div:last-child{
        margin-left: 5px;
    }
    .primary_btn{
        width: 90px;
        height: 30px;
    }
    .dialog_div_adduser{
        display: flex;
        justify-content: space-between;
        height: 70px;
    }
    .dialog_div_adduser div{
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        font-size: 14px;
        color: #969696;
    }
    .dialog_div_adduser div:first-child{
        margin-left: 0px;
    }
    .dialog_div_adduser_selected{
        color: #38A28A !important;
    }
    .group_Disable{
        border-bottom: #EEEEEE 1px solid;
        cursor: default;
        color: #969696;
    }
    .Disable_div{
        background-image: url('../../assets/img/Disable.png');
            width: 40px;
    height: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    }
    .fist_disable{
        display: flex;
        align-items: center;
    }
</style>>
