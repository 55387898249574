import request from "@/utils/request";
import { expr } from "jquery";
export function authGroupList(data) {
    return request({
        url: '/admin/base/authGroupList',
        method: 'get',
        params: data
    })
}
//获取路由列表
export function authRuleList(data) {
    return request({
        url: '/admin/base/authRuleList',
        method: 'get',
        params: data
    })
}

//禁用或启用用户组
export function authGroupEnable(data) {
    return request({
        url: '/admin/base/authGroupEnable',
        method: 'put',
        data: data
    })
}
//删除或启用用户组
export function authGroupDel(data) {
    return request({
        url: '/admin/base/authGroupDel',
        method: 'delete',
        data: data
    })
}
//用户组成员
export function authGroupAccess(data) {
    return request({
        url: '/admin/base/authGroupAccess',
        method: 'get',
        params: data
    })
}
//用户组规则保存
export function authGroupRule(data) {
    return request({
        url: '/admin/base/authGroupRule',
        method: 'post',
        data: data
    })
}
//用户组成员删除
export function authGroupUserDel(data) {
    return request({
        url: '/admin/base/authGroupUserDel',
        method: 'delete',
        data: data
    })
}
//添加用户组
export function authGroupAdd(data) {
    return request({
        url: '/admin/base/authGroupAdd',
        method: 'post',
        data: data
    })
}

//获取未分配成员列表
export function authGroupUserList(data) {
    return request({
        url: '/admin/base/authGroupUserList',
        method: 'get',
        params: data
    })
}
//用户组成员保存
export function authGroupUserSave(data) {
    return request({
        url: '/admin/base/authGroupUserSave',
        method: 'post',
        data: data
    })
}
//更新路由
export function authUpdateRoute() {
    return request({
        url: '/admin/base/authUpdateRoute',
        method: 'get'
    })
}